$border-width: 3px;
$border-radius: 1rem;

@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/solid";

.modal {
  --bs-modal-border-radius: 1rem;
}

body {
  position: relative;
}

@import "background";
@import "create-date";
@import "footer";
@import "home-links";
@import "navbar";
@import "open-calls";
@import "title";
