.card-stickynote {
  background-color: #ecd99a;
  .card-pin {
    --pin-size: 75px;
    position: absolute;
    top: -25px;
    right: -25px;
    width: var(--pin-size);
    height: var(--pin-size);
  }
}

.stickynotes-container {
  .stickynote {
    transform: rotate(-6deg);
    transition: transform .15s linear, box-shadow .15s linear;
    .card {
      box-shadow: 5px 5px 7px rgba(33,33,33,.7);
    }

    &:nth-child(even) {
      transform:rotate(4deg);
      position:relative;
      top:5px;
      .card {
        background:#cfc;
      }
    }
    &:nth-child(5n) {
      transform:rotate(-3deg);
      position:relative;
      top:-5px;
      .card {
        background:#ccf;
      }
    }
    &:nth-child(4n) {
      transform:rotate(5deg);
      position:relative;
      top:-10px;
    }

    &:hover {
      .card {
        box-shadow:10px 10px 7px rgba(0,0,0,.7);
      }
      transform: scale(1.25);
      position: relative;
      z-index: 5;
    }
  }

}

