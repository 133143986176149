.create-date-container {
  display: flex;
  justify-content: center;

  .loading {
    flex-grow: 1;
    color: white;

    background-image: url(../img/create-date/heart.png);
    background-size: contain;
    background-position: center;
    aspect-ratio: 1 / 1;
    max-width: 600px;

    .loading-text-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      padding-left: 30px;
      padding-right: 30px;

      h2 {
        font-size: 3rem;
        @include media-breakpoint-down(sm) {
          font-size: 2.2rem;
        }
      }
    }
  }
}