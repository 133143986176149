$logo-height: 68px;

.navbar {
  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    .navbar-logo {
      height: $logo-height;
    }
  }
}
.navbar-spacer {
  height: $logo-height + 16px;
}

.dropdown-menu {
  padding-top: 12px;
  padding-bottom: 12px;
}
