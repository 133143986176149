#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .site-content {
    flex: 1;
  }
}

.footer {
  background-color: rgb(59, 65, 65);
  .footer-title {
    color: white;
  }
  .footer-link {
    color: white;
  }
}
.footer-secondary {
  background-color: rgb(39, 44, 44);
}
