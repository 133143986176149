.home-link-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  .home-link {
    flex-basis: calc(100% / 3);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    a {
      text-decoration: none;
      img {
        aspect-ratio: 1 / 1;
        object-fit: contain;
        width: 300px;
        @include media-breakpoint-down(md) {
          width: 150px;
        }
      }
      p {
        margin-top: 8px;
        padding: 8px 16px;
        border-radius: 50rem;
        background-color: #eee;
        border: 3px solid #ddd;
        font-size: 1.2rem;
        font-weight: 500;
      }
    }
  }
}

// Floating Animation
$animation-duration: 4s;
$animation-delay: calc($animation-duration / 5);

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

$delay-mapping: (
  1: 1,
  2: 3,
  3: 5,
  4: 2,
  5: 4
);

.home-link {
  a {
    transform: translatey(0px);
    animation: float $animation-duration ease-in-out infinite;
  }
  @each $nth, $delay-offset in $delay-mapping {
    &:nth-child(#{$nth}) {
      a {
        animation-delay: calc(#{$delay-offset} * #{$animation-delay});
      }
    }
  }
}
